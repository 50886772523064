<template>
    <div class="card border-top rounded shadow p-3">
      <div class="row align-items-center">
        <div class="col-9">
          <b>Jumlah Document By Legal Drafter</b>
        </div>
      </div>
      <hr />
      <CChartBar
        :datasets="defaultDatasets"
        :labels="sims"
        :options="computedOptions"
        style="height:300px"
      />
    </div>
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'
import { deepObjectsMerge } from '@coreui/utils/src'
import {_} from 'vue-underscore';

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
    data() {
    return {
      sims: [],
      total: [],
      colors: []
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Jumlah document per legal drafter',
          backgroundColor: this.colors,
          data: this.total
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        responsive: true,
      }
    },
    computedOptions () {
      return deepObjectsMerge(this.defaultOptions, this.options || {})
    }
  },
    methods: {
      getRandomColor() {
          var letters = '0123456789ABCDEF';
          var color = '#';
          for (var i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
      },
      getGrafikVolumeTransactionBySim() {
          this.$store.dispatch("dashboard/getLegal").then((resp) => {
            resp.data.forEach(element => {
                this.sims.push(element.full_name);
                this.total.push(element.jumlah);
                this.colors.push(this.getRandomColor());
            });
        }).catch(e => {
          this.$toast.error(e);
        });
      },
      change() {
        this.getGrafikVolumeTransactionBySim();
      }
  },
  mounted() {
     this.getGrafikVolumeTransactionBySim();
  },
}
</script>
