<template>
 <div class="row">

  <div class="col-3">
    <div class="text-center">
      <img src="@/assets/logo/pp.png" width="60%" class="rounded-circle" alt="...">
      <h5 class="m-3">{{ user.full_name }}</h5>

      <div class="profile">
        <div>USERNAME: {{ user.username }}</div>
        <div>POSITION: {{ user.position }}</div>
        <div>EMAIL: {{ user.email }}</div>
        <div>ROLE: {{ user.roles ? user.roles.name : '-' }}</div>
        <div class="mt-1">{{ user.regional_apparatus ? user.regional_apparatus.name : '-' }}</div>
      </div>
    </div>
  </div>

  <div class="col-9">
    <CCard>
      <CCardBody class="dashboard-card">
        <h4>Selamat Datang, {{ user.full_name }}!</h4>
        <div>Anda telah memasuki aplikasi <b>SIMANGJALU</b> dengan hak akses sebagai <b>{{ user.roles ? user.roles.name : '-' }}</b>.</div>
        <div>Untuk menggunakan aplikasi ini secara cepat, silahkan gunakan tombol jalan pintas dibawah ini.</div>
        <hr />
        <div class="row">

          <!-- <div class="col-6">
            <CCard @click="onClick('create_eproduct')" class="text-center" style="background: #26a69a !important; color:white; cursor: grab">
              <img src="@/assets/contract.png" class="center mt-3 mb-3" width="80px" alt="...">
              <p class="mb-2">Buat Online Chat </p>
            </CCard>
          </div> -->

          <!-- <div class="col-3">
            <CCard @click="onClick('status')" class="text-center" style="background: #e26a6a !important; color:white; cursor: grab">
              <img src="@/assets/clipboard.png" class="center mt-3 mb-3" width="80px" alt="...">
              <p class="mb-2">Status Produk Hukum</p>
            </CCard>
          </div> -->

          <div class="col-6">
            <CCard @click="onClick('change_profile')" class="text-center" style="background: #8e44ad !important; color:white; cursor: grab">
              <img src="@/assets/profile.png" class="center mt-3 mb-3" width="80px" alt="...">
              <p class="mb-2">Ubah Profile</p>
            </CCard>
          </div>

          <!-- <div class="col-3">
            <CCard @click="onClick('report')" class="text-center" style="background: #f4d03f !important; color:white; cursor: grab">
              <img src="@/assets/report.png" class="center mt-3 mb-3" width="80px" alt="...">
              <p class="mb-2">Rekapitulasi</p>
            </CCard>
          </div> -->
          
        </div>
      </CCardBody>
    </CCard>

    <!-- <CCard v-if="isStatus">
      <CCardBody>
          Status Produk Hukum
          <hr />
          <div class="row">
            <div class="col-3 mt-1" v-for="item in statuses" :key="item.status">
              <span v-if="item.status == 'PROSES' || item.status == 'DIEDIT' " class="badge bg-warning" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ item.status }}</span>
              <span v-else class="badge bg-primary" style="color:white">{{ item.status }}</span>
              <h4 class="mt-2">{{ item.jumlah }}</h4>
            </div>
          </div>
      </CCardBody>
    </CCard> -->

    <CCard v-if="isChangeProfile">
      <CCardBody>
          Ubah Profile
          <hr />
          <div class="row">
            <div class="col">
              <CInput
                v-model="user.full_name"
                label="Nama Lengkap"
                placeholder="ketik disini"
              />
              <CInput
                v-model="user.position"
                label="Jabatan"
                placeholder="ketik disini"
              />
              <CInput  
                v-model="user.username"
                label="username"
                placeholder="ketik disini"
              />
              <CInput
                v-model="user.email"
                label="Email"
                type="email"
                placeholder="test@email.com"
              />           
              <div>
                <button @click="update" class="btn btn-primary">
                  Update User
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <CInput
                v-model="user.password"
                label="Password"
                type='password'
                placeholder="ketik disini"
                :is-valid="isPasswordValid"
                @update:value="inputPassword()"
                :lazy="false"
              />
            </div>
            <div class="col-12">
              <CInput
                v-model="user.password_confirmation"
                label="Konfirmasi Password"
                type='password'
                placeholder="ketik disini"
              />
            </div>
            <div class="col-12">
               <button @click="updatePassword" class="btn btn-primary">
                Update Password
              </button>
            </div>
          </div>
      </CCardBody>
    </CCard>

    <CCard v-if="isRekap">
      <CCardBody>
          Rekapitulasi
          <hr />
          <VolumeTransaction />
          <VolumeTransactionBySimType />
      </CCardBody>
    </CCard>
  </div>
 </div>
</template>

<script>
import VolumeTransaction from "../charts/VolumeTransaction.vue";
import VolumeTransactionBySimType from "../charts/VolumeTransactionBySimType.vue";
import CChartDoughnutExample from "../charts/VolumeTransacationByPaymentMethod.vue";
export default {
  components: {
    VolumeTransaction,
    VolumeTransactionBySimType,
    CChartDoughnutExample,
  },
  data() {
    return {
      user: {},
      updateModal: false,
      form: {},
      roles: [],
      rooms: [],
      inbox: [],
      statuses: [],
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 3,
      },
      isStatus: true,
      isChangeProfile: false,
      isRekap: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: '',
    };
  },
  methods: {
     validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = 'Password kurang dari 6 karakter!!';
        this.isPasswordValid = false;
        return false;
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = 'Konfirmasi password tidak sama!!';
        this.isPasswordValid = false;
        return false;
      } else {
        this.invalidPassword = '';
        this.isPasswordValid = null;
        return true;
      }
    },
     updatePassword() {
      if (this.validatePassword(this.user)) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("user/changePassword", { id: this.user.id, password: this.user.password })
          .then(() => {
            this.$toast.success("Berhasil merubah password user");
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      } else {
        this.$toast.error(this.invalidPassword);
      }
    },
      inputPassword(){
        this.invalidPassword = '';
        this.isPasswordValid = null;
      },
     update() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("user/updateUser", { id: this.user.id, data: this.user })
        .then(() => {
          this.$toast.success("Berhasil merubah data user");
          loading.hide();
        })
        .catch((e) => {
          loading.hide();
        });
    },
    onClick(params) {
      switch (params) {
        case 'create_eproduct':
          this.$router.replace({ path: "online-chat" });
          break;
        case 'status':
          
             this.isStatus = true;
             this. isChangeProfile = false;
             this.isRekap = false;
          
          break;
        case 'change_profile':
    
             this.isChangeProfile = true;
             this. isStatus = false;
             this.isRekap = false;
         
          break;
        case 'report':
         
             this.isRekap = true;
             this.isStatus = false;
             this.isChangeProfile = false;
          
          break;
        default:
          break;
      }
    },
    getMe() {
      this.$store
        .dispatch("auth/me")
        .then((resp) => {
          this.user = resp.data;
        })
        .catch((e) => {
        });
    },
    getStatus() {
      this.$store
        .dispatch("dashboard/getStatus")
        .then((resp) => {
          this.statuses = [];
          resp.data.forEach(element => {
            if (this.user.verificator == 1 && (element.status == 'PENGAJUAN' || element.status == 'DIKEMBALIKAN' || element.status == 'PROSES')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 2 && (element.status == 'PROSES' || element.status == 'DIEDIT' || element.status == 'LEGAL DRAFTING')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 3 && (element.status == 'LEGAL DRAFTING' || element.status == 'APPROVED SUNCANG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 4 && (element.status == 'APPROVED SUNCANG' || element.status == 'APPROVED KASUBAG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 5 && (element.status == 'APPROVED KASUBAG' || element.status == 'APPROVED KABAG')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 6 && (element.status == 'APPROVED KABAG' || element.status == 'APPROVED ASSISTANT')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 7 && (element.status == 'APPROVED ASSISTANT' || element.status == 'APPROVED SEKDA')) {
              this.statuses.push(element)
            } else if (this.user.verificator == 0) {
               this.statuses.push(element)
            }
          });
        })
        .catch((e) => {
        });
    },
  },
  mounted() {
    this.getMe();
    this.getStatus();
  },

  computed: {
    computedRole() {
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
  },
};
</script>

<style scoped>
.border-top {
  border-top: 3px solid #1d4374 !important;
}

.scroll {
  overflow-y: auto;
  height: 150px;
}
</style>

<style>
.table {
  display: table;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.rounded-circle {
    border-radius: 50% !important;
    border: 5px solid black;
}

.profile {
  color: #5b9bd1;
  font-weight: bold;
}

.dashboard-card {
  background-color: #dff0d8;
  padding-bottom: 0;
}

img.center {
    display: block;
    margin: 0 auto;
}
</style>